"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostOrigin = void 0;
var PostOrigin;
(function (PostOrigin) {
    PostOrigin[PostOrigin["WEB_APP"] = 1] = "WEB_APP";
    PostOrigin[PostOrigin["NATIVE_APP"] = 2] = "NATIVE_APP";
    PostOrigin[PostOrigin["MS_TEAMS"] = 3] = "MS_TEAMS";
    PostOrigin[PostOrigin["SLACK"] = 4] = "SLACK";
})(PostOrigin || (exports.PostOrigin = PostOrigin = {}));
