import React from 'react';
import { InputAdornment, Input, IconButton } from '@material-ui/core'; // react component for creating dynamic tables
import MaterialTable from 'material-table';
import SaveIcon from '@material-ui/icons/Save';
import { getPreferences, setPreference } from 'helpers/api/preferences';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useQuery, useQueryClient } from 'react-query';

const PREFERENCES_DETAILS = {
  admin_fee_percentage:
    'A number between 0 and 1 to represent the percentage, eg: 0.1 for 10%, 0.25 for 25%',
  wire_transfer_minimum:
    'The minimum amount for a wire transfer payment option to be available.',
};

const PREFERENCE_VALUE_FORMATTER = {
  admin_fee_percentage: (value) =>
    value ? `${(100 * parseFloat(value)).toFixed(2)}%` : '',
};

const Preferences = ({ accountId }) => {
  const queryClient = useQueryClient();
  const { data: preferences = [] } = useQuery(
    ['account.preferences', accountId],
    () => getPreferences(accountId)
  );

  React.useEffect(() => {
    setEditPreferences(
      preferences.map((pref) => {
        const formatter =
          PREFERENCE_VALUE_FORMATTER[pref.key] || ((value) => String(value));
        const description = PREFERENCES_DETAILS[pref.key] || '';
        return { ...pref, currentValue: formatter(pref.value), description };
      })
    );
  }, [preferences]);

  const [editPreferences, setEditPreferences] = React.useState([]);

  const setValue = (key, value) => {
    setEditPreferences((prev) => {
      const newPreferences = prev.map((pref) => {
        if (pref.key === key) {
          return { ...pref, value };
        }
        return pref;
      });
      return newPreferences;
    });
  };

  const onSavePreference = React.useCallback(
    (key, value) =>
      setPreference(accountId, key, value)
        .catch((err) => {
          console.error('Error saving preference', err);
          alert('Error saving preference');
        })
        .then(() => {
          queryClient.invalidateQueries(['account.preferences', accountId]);
        }),
    [accountId, queryClient]
  );

  return (
    <GridContainer>
      <GridItem xs={12}>
        <MaterialTable
          title=""
          columns={[
            { title: 'Name', field: 'text', defaultSort: 'asc' },
            { title: 'Current Value', field: 'currentValue' },
            {
              title: 'New Value',
              field: 'value',
              render: (rowData) => (
                <Input
                  id="custom-licence-amount"
                  value={rowData.value}
                  onChange={(event) =>
                    setValue(rowData.key, event.target.value)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="save"
                        onClick={() =>
                          onSavePreference(rowData.key, rowData.value)
                        }
                      >
                        <SaveIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              ),
              align: 'right',
            },
            { title: 'Description', field: 'description' },
          ]}
          data={editPreferences}
          options={{
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
          }}
          style={{ boxShadow: 'none' }}
        />
      </GridItem>
    </GridContainer>
  );
};

export default Preferences;
