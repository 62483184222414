"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleType = void 0;
var RoleType;
(function (RoleType) {
    RoleType["ADMINISTRATORS"] = "system-administrators";
    RoleType["MANAGERS"] = "system-managers";
    RoleType["EMPLOYEES"] = "system-employees";
    RoleType["CUSTOM"] = "custom";
})(RoleType || (exports.RoleType = RoleType = {}));
